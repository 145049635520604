import React from 'react';

interface Props {}

const Contact: React.FC<Props> = (props) => {
    return (
        <div className="Contact">
            ova e contact
        </div>
    );
};

export default Contact;