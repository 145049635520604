import React from 'react';
import logo from './logo.svg';
import '../src/styles/App.scss';
import "../src/styles/sass/_home.scss"
import "../src/styles/sass/_navbar.scss"
import "../src/styles/sass/_about.scss"
import "../src/styles/sass/_contact.scss"
import "../src/styles/sass/_work.scss"


import { Link, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Work from './components/Work';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      {/* <Navbar/>  */}
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="work" element={<Work />} />
        </Routes>
    </div>
  );
}

export default App;
