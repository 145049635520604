import React from 'react';

interface Props {}

const Work: React.FC<Props> = (props) => {
    return (
        <div className="Work">
          ova e work page  
        </div>
    );
};

export default Work;