import React from 'react';

interface Props {}

const About: React.FC<Props> = (props) => {
    return (
        <div className="About">
           THIS IS ABOUT 
        </div>
    );
};

export default About;